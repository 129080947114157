<template>
    <div>
        <coverImage
        title="Trip Planner"
        image="trip-planner.png"
        >
            <template v-if="$route.name === 'plansList' || $route.path === '/plan-trip'" v-slot:addButton>
                <v-btn absolute top right x-large color="primary" elevation="0" class="rounded-lg px-5" @click="$router.push({ name: 'quickPlanner' })">
                    <v-icon>mdi-plus</v-icon>
                    add new trip
                </v-btn>
            </template>
        </coverImage>
        <!-- <div class="main"> -->
            <router-view :key="$route.path"></router-view>
        <!-- </div> -->
    </div>
</template>

<script>
import coverImage from '@/components/static_sections/cover-image.vue'

export default {
  components: { coverImage }
}
</script>

<style>
.v-text-field--outlined fieldset {
  border-color: #cecece !important;
  border-width: 0.5px;
}
.google-ac {
  outline: none;
  border: solid 1px #cecece;
  width: 100%;
  padding: 8px;
  height: 56px;
}
</style>
